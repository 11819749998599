.btn-success {
    color: #fff;
    background-color: #78B72E !important;
    border-color: #78B72E !important;
}

.btn-success:hover {
    background-color: #5A8923 !important;
    border-color: #5A8923 !important;

}

.btn-primary {
    color: #fff;
    background-color: #0376BB !important;
    border-color: #0376BB !important;
}

.btn-primary:hover {
    background-color: #02598C !important;
    border-color: #02598C !important;

}

