body{
  margin: 0 !important;
  // font-family: -apple-system,BlinkMacSystemFont,
  //               "Segoe UI",
  //               Roboto,
  //               "Helvetica Neue",
  //               Arial,
  //               "Noto Sans",
  //               sans-serif,
  //               "Apple Color Emoji",
  //               "Segoe UI Emoji",
  //               "Segoe UI Symbol",
  //               "Noto Color Emoji" !important;
  font-family: 'Roboto', sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #212529 !important;
  text-align: left !important;
  background-color: #fff;
}

.container{
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.list-group{
  max-height: 400px;
  margin-bottom: 10px;
  overflow-y:scroll;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] { -moz-appearance:textfield; }

.MuiFormControl-marginNormal{
  margin-top: 0 !important;
}