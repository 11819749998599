/* Modal */
.ReactModalPortal > div{
  opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  transition: opacity .2s ease-in-out;
  z-index: 999;
}

.modal-fondo {
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal-style {
  background: white;
  border-radius: 5px;
  color: rgb(51, 51, 51);
  display: inline;
  max-height: 620px;
  max-width: 500px;
  outline: none;
  padding: 10px;
}

/* The Modal (background) */
.Modal {
  position: fixed;
  z-index: 500;
  background-color: white;
  width: 70%;
  border: 1px solid #ccc;
  padding: 16px;
  left: 15%;
  top: 30%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
  .Modal {
      width: 500px;
      left: calc(50% - 250px);
  }
}