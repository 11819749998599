.contabox{
    border-color: snow !important;
    margin-top: 15px; 
}

.navbar-light .navbar-nav .nav-link {
    color: white !important;
}

.text-header-landing{
    font-weight: bold;
}

.a-landing{
    display: block;
    padding: .5rem 1rem;
    color: rgba(0,0,0,.5);
}

.a-landing:hover{
    color: #72c02c;
    text-decoration: none;
}

.contabox-login-register{
    font-size: 70%;
}

.contabox-customer{
    border-color: #fffafa00 !important;
    margin-top: 15px; 
    color: white !important;
}

.rounded-contabox{
    border-radius: 1.25rem!important;
}

.p-3-contabox {
    padding: .5rem!important;
}

//NavBar Inicio

.bg-dark-contabox-landing {
    background-color: #043F62!important;
}

.text-ventas-acliente{
    font-size: 60%;
}

.a-subrayado{
    color: #78B82D;
}

.a-subrayado:hover { 
    color: #90E227;
    text-decoration: none;
}

.bg-light-contabox-landing {
    background-color: #fff !important;
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border-radius: .25rem;
}

//NavBar Customer

// .bg-dark-contabox {
//     background-color: #043F62 !important;
// }

.bg-contabox{
    background-color: #0376BB!important;
}

.a-contabox{
    display: block;
    padding: .5rem 1rem;
    color: #F2F2F2;
}

.a-contabox:hover{
    color: #78B82D;
    text-decoration: none;
}


.navbar-custom {
    background-color: #3f51b5; // Cambia este color según tus necesidades
  }
  
  .navbar-title {
    flex-grow: 1; // Hace que el título ocupe el máximo espacio disponible
    color: #F5E7E4;
  }
  
  .navbar-links {
    display: flex;
    align-items: center;
    gap: 10px; // Espacio entre los elementos
  }
  
  .a-contabox {
    color: white;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .a-contabox:hover {
    background-color: rgba(255, 255, 255, 0.1); // Efecto hover para los enlaces
  }
  
  .a-table{
    background-color: #81BECE;
  }



  .navbar-custom {
    display: flex;
    justify-content: space-between;
  }
  
  .navbar-title {
    margin-left: 16px;
  }
  
  .navbar-links {
    display: flex;
    align-items: center;
  }
  